import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function LeftAngle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.023"
      height="47.218"
      viewBox="0 0 25.023 47.218"
      style={svgStyle}
    >
      <path
        id="路径_1"
        data-name="路径 1"
        d="M3606.181,4747.273l-22.9-22.9,22.9-22.9"
        transform="translate(-3581.865 -4700.763)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
}
